import {motion} from 'framer-motion';
import {down} from 'styled-breakpoints';
import styled, {css} from 'styled-components';

export const Wrapper = styled(motion.div)`
  width: 100%;
  max-width: 300px;
  ${down('md')} {
    max-width: 210px;
  }
  margin-right: 48px;
  float: right;
  position: fixed;
  left: 20%;
  top: 8%;
  z-index: 9999;
  .me-auto {
    width: 100%;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
  }

  ${down('md')} {
    right: 0%;
  }

  ${(props) =>
    props?.hide &&
    css`
      display: none;
    `}
`;
