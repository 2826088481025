export const theme = {
  fontSize: {
    headline1: '30px',
    headline2: '25px',
    headline3: '20px',
    headline4: '16px',
    body1: '16px',
    body2: '14px',
    cta: '14px',
    microcopy: '12px',
  },
  lineHeight: {
    headline1: 1.5,
    headline2: 1.3,
    headline3: 1.2,
    headline4: 1.5,
    body1: 1.6,
    body2: 1.46,
    cta: 1.7,
    microcopy: 1.5,
  },
  fontWeight: {
    regular: 400,
    semibold: 500,
    bold: 600,
  },
  fontFamily: "'Saira', sans-serif",
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  elevation: {
    level1: '0px 8px 16px rgba(0, 0, 0, 0.05)',
    level2: '0px 2px 5px rgba(172, 172, 190, 0.24)',
  },
  colors: {
    textBody: '#5f5f8c',
    textMedium: '#5e5e73',
    textDark: '#1e1e2f',
    textMuted: '#bfbfd4',
    green200: '#d3eee3',
    green100: '#f0faf6',
    green300: '#47b881',
    green400: '#1a9e68',
    green500: '#024b27',
    blue700: '#54a0ae',
    blue500: '#55a6f1',
    blue400: '#084b8a',
    blue300: '#1070ca',
    blue200: '#dbeaf7',
    blue100: '#f6f9fd',
    warmBlue: '#3b3dda',
    purple200: '#e4e5fb',
    purple300: '#585adf',
    purple350: '#3436a2',
    purple100: '#f6f6fe',
    purple400: '#28284d',
    red300: '#f39c9a',
    red400: '#d52a2a',
    red200: '#f7e4e4',
    red100: '#fef6f6',
    red500: '#661515',
    orange200: '#ffe3c8',
    orange300: '#e6821d',
    orange100: '#fef8f2',
    orange400: '#703f0f',
    darkBluePurple: '#605F8C',
    borderLight: '#ededf2',
    paleLilacTwo: '#e5e5eb',
    backgroundDark: '#f6f6f8',
    backgroundLight: '#fafafa',
    borderDark: '#cecede',
    blackTwo: '#242424',
    blackThree: '#171717',
    white: '#ffffff',
    black: '#000000',
    //
    yellow100: '#FFD712',
  },
};
