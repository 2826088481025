import { memo, useContext, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { CiMail } from "react-icons/ci";
import { CloseIcon } from "../../assets/icons/close";
import { CustomModal } from "./contactModal.styles";
import { BsArrowRight } from "react-icons/bs";
import { activateNotification } from "../../utils/notifications-utils";
import { validateEmail } from "../../utils/email-utils";
import { contactUs } from "../../api/firebase.requests";
import { ContactUsContext } from "../../contactusProvider/provider";

export const ContactModal = memo(({ toggle }) => {
  const { message, setMessage, isOpen, setIsOpen } =useContext(ContactUsContext);
  const [loading, setLoading] = useState(false);

  const handleMessageSubmit = async () => {
    if (
      message.surname.length === 0 ||
      message.lastname.length === 0 ||
      message.email.length === 0 ||
      message.phonenumber.length === 0 ||
      message.message.length === 0
    ) {
      activateNotification({
        title: "Error",
        body: "Complete all fields",
      });
    } else {
      if (validateEmail(message.email) === true) {
        setLoading(true);
        await contactUs(message)
          .then((data) => {
            activateNotification({
              title: "Success",
              body: "Message sent. We'll provide feedback to your email.",
            });
            // console.log(data);
          })
          .catch((err) => {
            activateNotification({
              title: "Error",
              body: "An error occurred",
            });
          });
        toggle();
        setLoading(false);
      } else {
        activateNotification({
          title: "Error",
          body: "This email address is not valid",
        });
      }
    }
  };
  return (
    <CustomModal isOpen={isOpen} toggle={toggle}>
      <Form>
        <ModalHeader toggle={toggle}>
          Love to <span className="green">hear from you,</span> <br />
          Get in touch!
          <div className="close-btn" onClick={toggle}>
            <CloseIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="exampleEmail">First name</Label>
                <InputGroup>
                  <InputGroupText>
                    <CiMail size={25} />
                  </InputGroupText>
                  <Input
                    placeholder="Enter your first name"
                    name="surname"
                    value={message.surname}
                    onChange={(e) =>
                      setMessage((message) => ({
                        ...message,
                        surname: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="exampleEmail">Last name</Label>
                <InputGroup>
                  <InputGroupText>
                    <CiMail size={25} />
                  </InputGroupText>
                  <Input
                    placeholder="Enter your last name"
                    name="lastname"
                    value={message.lastname}
                    onChange={(e) =>
                      setMessage((message) => ({
                        ...message,
                        lastname: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <InputGroup>
              <InputGroupText>
                <CiMail size={25} />
              </InputGroupText>
              <Input
                placeholder="Enter your email address"
                name="email"
                value={message.email}
                type="email"
                onChange={(e) =>
                  setMessage((message) => ({
                    ...message,
                    email: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Phone Number</Label>
            <InputGroup>
              <InputGroupText>
                <CiMail size={25} />
              </InputGroupText>
              <Input
                placeholder="Enter your phone number"
                name="phonenumber"
                value={message.phonenumber}
                onChange={(e) =>
                  setMessage((message) => ({
                    ...message,
                    phonenumber: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Message</Label>
            <Input
              name="message"
              type="textarea"
              rows="7"
              value={message.message}
              onChange={(e) =>
                setMessage((message) => ({
                  ...message,
                  message: e.target.value,
                }))
              }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleMessageSubmit}>
            <span>Submit</span>
            {loading ? (
              <Spinner size="sm">Loading...</Spinner>
            ) : (
              <BsArrowRight size={25} />
            )}
          </Button>
        </ModalFooter>
      </Form>
    </CustomModal>
  );
});
