import {Store} from 'react-notifications-component';
import {ToastComponent} from '../components/toast/toast.component';

export const activateNotification = (props) => {
  const Toast = <ToastComponent {...props} />;

  Store.addNotification({
    content: Toast,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
};
