import React, { createContext, useContext, useState } from "react";

export const ContactUsContext = createContext({});
// export const useContactUsContext = () => useContext(ContactUsProvider);

const ContactUsProvider = ({ children }) => {
  const [theme, setTheme] = useState(false);
  const [message, setMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ContactUsContext.Provider
      value={{ theme, message, setMessage, isOpen, setIsOpen }}
    >
      {children}
    </ContactUsContext.Provider>
  );
};

export default ContactUsProvider;
