import { between, down, up } from "styled-breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  /* padding: 0 0 0.748rem 0; */
  background: #f3faf2;
  width: 100%;
  overflow: hidden;
  .privacy_and_conditions {
    background-color: #262626;
    padding: 24px 0 24px 0;
    .d-flex.container {
      column-gap: 1rem;
      ${down("sm")} {
        flex-wrap: wrap;
        row-gap: 14px;
        column-gap: 0;
      }
      a:nth-child(2) {
        border-left: 1px solid white;
        padding-left: 20px;
      }
      a {
        flex-grow: 0;
        flex: none;
        ${down("sm")} {
          flex: 1 1 0%;
        }
      }
      p.copyright-text {
        flex: 1 1 0%;
        text-align: end;
        ${down("sm")} {
          flex: none;
          flex-basis: 100%;
          text-align: center;
        }
      }
      & > * {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity)) /* #ffffff */;
      }
      a,
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
      }
      p {
        text-align: end;
      }
    }
  }
  .first_section {
    .container {
      margin: auto;
      display: grid;
      align-items: center;
      padding: 40px 0 40px 0;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      ${down("md")} {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 3rem;
        text-align: center;
      }
      .navigations_and_contact {
        grid-column: span 2 / span 2;
        ${down("md")} {
          width: 100%;
          padding: 0rem 4rem;
        }
        ${between("xs", "sm")} {
          width: 100%;
          padding: 0rem 3rem;
        }

        .context {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          & > * {
            flex-basis: 33.333333%;
          }
          ${down("md")} {
            display: flex;
            flex-direction: column-reverse;
            & > * {
              flex-basis: 100%;
            }
          }
          div {
            h5.title {
              font-size: 16px;
              --tw-text-opacity: 1;
              color: rgb(9 152 25 / var(--tw-text-opacity)); /* #099819 */
              font-weight: 500;
            }
            h6.subtitle {
              font-size: 14px;
            }
            .google-store-link, .apple-store-link{
              display: contents;
            }
          }
          .newsletter {
            .input-group {
              .input-group-text {
                border-radius: 50px;
                background-color: unset;
                border: 1px solid #262626;
                border-right: none;
                padding-right: 0;
              }
              .form-control {
                border: 1px solid #262626;
                border-left: 0;
                border-radius: 50px;
                padding: 14px;
                padding-left: 8px;
              }
              .form-control:focus {
                box-shadow: none;
              }
            }
          }
          .download {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            .apple-store-link svg {
              ${down("md")} {
                height: 100% !important;
                width: 101px !important;
              }
              ${up("md")} {
                height: 100% !important;
              }
            }
          }
          .navigation {
            ul li {
              line-height: 30px;
            }
          }
          .newsletter {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            ${down("md")} {
              width: 100%;
            }

            div.fancy-input-wrapper {
              border: 1px solid #0b9c07;
              border-radius: 36px;
              padding: 4px;
              max-width: 100%;
              ${up("md")} {
                max-width: 90%;
              }
              .mail-icon {
                padding: 10px;
              }
              input {
                border: none;
                background: unset;
                padding-left: 0;

                &:focus {
                  box-shadow: none;
                }
              }
              input#email::placeholder {
                font-size: 12px;
                color: #808080;
              }
              input#email:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px white inset;
              }

              input#email:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0 50px white inset;
                -webkit-text-fill-color: #333;
              }

              button {
                white-space: nowrap;
                border-radius: 36px;
                background: #0b9c07;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;

                border: none;
                padding-left: 1.4rem;
                padding-right: 1.4rem;

                span {
                  margin-right: 10px;
                }
                ${down("md")} {
                  font-size: 12.1739px;
                  padding-left: 0.822rem;
                  padding-right: 0.822rem;
                  svg {
                    width: 12px !important;
                    height: 12px !important;
                  }
                }
              }
            }
            form {
              .form_group {
                max-width: 100%;
                position: relative;
                border-radius: 900px;
                overflow: hidden;
                outline: 1px solid green;
                ${up("md")} {
                  max-width: 90%;
                }
                input[type="email"] {
                  width: 100%;
                  background: transparent;
                  padding: 10px;
                  padding-inline-start: 2.2rem;
                  border: none;
                  font-size: 12px;
                }
                span.form_icon {
                  position: absolute;
                  font-size: 14px;
                  right: 100%;
                  transform: translate(0px, -50%);
                  left: 10px;
                  top: 50%;
                }
              }
              button {
                font-size: 14px;
                font-weight: 600;
                line-height: 16.8px;
                text-align: center;
                color: #fafafa;
                padding: 0.6rem 1.2rem;
                ${down("md")} {
                  width: 100%;
                }
              }
            }
          }
          ${down("md")} {
            .download,
            .navigation {
              display: none;
            }
          }
        }
      }
      .contact_us {
        /* display: none */
      }
      .social_links {
        display: flex;
        flex-direction: column;
        column-gap: 10px;
        ${down("md")} {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0rem 4rem;
        }
        ${between("xs", "sm")} {
          width: 100%;
          padding: 0rem 3rem;
        }
        .agrogrid_brand {
          width: 143.64px;
          ${down("md")} {
            max-width: 107.73px;
          }
        }
        .social_btns {
          margin-top: 40px;
          ${down("md")} {
            margin-top: 20px;
          }
          p {
            margin: 1rem 0;
            font-weight: 500;
            color: #099819;
          }
          div {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
  hr {
    margin: 0 0;
  }
  .contact_us {
    ${down("sm")} {
      display: none;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 24px 0 24px 0;
      div {
        display: flex;
        align-items: center;
        flex-wrap: no-wrap;
        column-gap: 5px;
        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          text-align: center;
        }
      }
    }
  }
`;
