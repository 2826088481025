import {between, down, up} from 'styled-breakpoints';
import styled from 'styled-components';
export const Wrapper = styled.div`
  background: transparent;
  padding: 0.625rem 1.3125rem;
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;

  nav.navbar {
    position: relative;
    background: #f1f1f1f2 !important;
    /* box-shadow: 2px 3px 9px 4px #eaeaea; */
    padding: 1.2rem 0.9rem;
    border-radius: 16px;
    ${up("md")} {
      ${"" /* width: 87%; */}
      padding-left: 7%;
      padding-right: 7%;
      margin-left: auto;
      margin-right: auto;
    }
    .menu_links {
      ${up("md")} {
        display: none;
      }
      display: block;
      position: absolute;
      background-color: #f1f1f1e5;
      height: 200px;
      z-index: -1;
      top: 120%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      border-radius: 12px;
      padding: 1rem;
      max-height: 50vh;
      overflow: auto;
      ul {
        & > :not([hidden]) ~ :not([hidden]) {
          --tw-divide-y-reverse: 0;
          border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
          border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
          border-color: #cac8c8;
        }
        li {
          padding: 8px 0px;
          .nav_link, a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            line-height: 22.04px;
            ${down("md")} {
              font-weight: 600;
            }
          }
          .nav_link.active {
            color: #099819;
          }
        }
      }
    }
  }
  .navbar_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
    }
    .md_home_nav_links {
      ${up("md")} {
        display: none !important;
        visibility: hidden;
      }
      display: flex;
      align-items: center;
      column-gap: 1rem;
      cursor: pointer;
      visibility: visible;
      button {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        padding: 4px 5px;
        column-gap: 1px;
      }
    }
    .home_nav_links {
      ${down("md")} {
        display: none;
        visibility: hidden;
      }
      ul {
        display: flex;
        align-items: center;
        column-gap: 35px;
        visibility: visible;

        li {
          padding: 10px 0px 10px 0px;
          .nav-link,
          a {
            font-size: 14px;
            font-weight: 500;
            ${down("md")} {
              font-size: 15px;
            }
          }a.active{
            color: #099819;
          }
          --bs-nav-link-color: #262626;
          --bs-nav-link-hover-color: #098016;
        }
      }
    }
    .agrogid_brand {
      max-width: 143.64px;
      margin: auto;
      flex: 1 1 auto;
      ${down("md")} {
        margin: 0;
        max-width: 90px;
      }
      img {
        width: 100%;
      }
    }
  }
`;
