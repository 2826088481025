import React, { useContext, useEffect, useRef, useState } from "react";

import { Wrapper } from "./navigation.styles";
import AgrogridBrandLogo from "../../assets/images/agrogrid-brand.png";
import { Button } from "../Button";
import { NavLink } from "react-router-dom";
import { ContactUsContext } from "../../contactusProvider/provider";
import { ContactModal } from "../contactModal";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxArrowTopRight } from "react-icons/rx";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useParams } from "react-router";

export const NavigationComponent = () => {
  const { setIsOpen, isOpen } = useContext(ContactUsContext);
  const {farm_slug} = useParams();
  const toggle = () => setIsOpen && setIsOpen(!isOpen);
  const [openMdMenu, setOpenMdMenu] = useState(false) 
  const toggleMenu = () => setOpenMdMenu(!openMdMenu);
  const location = useLocation();

  const isActiveLink = (urlArr) => {
    return urlArr.some((url) => url === window.location.pathname)
  };
  const menuRef  = useRef()

 useEffect(() => {
   if (location.hash) {
     const element = document.querySelector(location.hash);
     if (element) {
       element.scrollIntoView({ behavior: "smooth" });
     } else {
       window.scrollTo(0, 0);
     }
   }
   if (farm_slug != undefined) {
     window.scrollTo(0, 0);
   }
 }, [location]);
  return (
    <Wrapper ref={menuRef}>
      <nav className="navbar">
        <div className="navbar_content">
          <div className="home_page_links home_nav_links">
            <ul className="">
              <li>
                <a
                  className={`nav_link ${
                    location.pathname === "/" && "active"
                  }`}
                  href="/"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className={`nav_link ${
                    location.hash === "#our-story" && "active"
                  }`}
                  href="/#our-story"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
          <a href="/" className="agrogid_brand">
            <img
              src={AgrogridBrandLogo}
              alt="Agrogrid Brand Logo"
              className="agrogrid-brand"
            />
          </a>
          <div className="extensives_link home_nav_links">
            <ul className="">
              <li>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname = "/farms";
                  }}
                  className={`nav_link`}
                  to="/farms"
                >
                  Our Farms
                </NavLink>
              </li>
              <li>
                <NavLink className={`nav_link`} to="/blog">
                  Blog
                </NavLink>
              </li>
              <li>
                <Button
                  onClick={toggle}
                  className="nav_link"
                  icon={<RxArrowTopRight strokeWidth={1.1} />}
                >
                  Contact Us
                </Button>
              </li>
            </ul>
          </div>
          <div className="md_home_nav_links">
            <Button
              onClick={toggle}
              className="nav_link"
              icon={<RxArrowTopRight strokeWidth={1.1} />}
            >
              Contact Us
            </Button>
            {openMdMenu ? (
              <svg
                onClick={toggleMenu}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 5L12 12M12 12L19 5M12 12L5 19M12 12L19 19"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <RxHamburgerMenu
                onClick={toggleMenu}
                color="#000000"
                size={24}
                strokeWidth={0.8}
              />
            )}
          </div>
          <AnimatePresence>
            {openMdMenu && (
              <motion.div
                initial={{ top: 0, opacity: 0.3 }}
                animate={{ top: "120%", opacity: 1 }}
                exit={{ top: "-120%", opacity: 0 }}
                className="menu_links"
              >
                <ul>
                  <li onClick={toggleMenu}>
                    <a
                      className={`nav_link ${
                        location.pathname === "/" && "active"
                      }`}
                      href="/"
                    >
                      Home
                      <RxArrowTopRight
                        strokeWidth={0.8}
                        size={20}
                        color="inherit"
                      />
                    </a>
                    <span></span>
                  </li>
                  <li onClick={toggleMenu}>
                    <NavLink
                      className={`nav_lnav_link d-flex align-items-center justify-betweenink ${
                        location.hash === "#our-story" && "active"
                      }`}
                      to="/#our-story"
                    >
                      About Us
                      <RxArrowTopRight
                        strokeWidth={0.8}
                        size={20}
                        color="inherit"
                      />
                    </NavLink>
                    <span></span>
                  </li>
                  <li onClick={toggleMenu}>
                    <NavLink
                      className="nav_link d-flex align-items-center justify-between"
                      to="/farms"
                    >
                      Our Farms
                      <RxArrowTopRight
                        strokeWidth={0.8}
                        size={20}
                        color="inherit"
                      />
                    </NavLink>
                    <span></span>
                  </li>
                  <li onClick={toggleMenu}>
                    <NavLink
                      className="nav_link d-flex align-items-center justify-between"
                      to="/blog"
                    >
                      Blog
                      <RxArrowTopRight
                        strokeWidth={0.8}
                        size={20}
                        color="inherit"
                      />
                    </NavLink>
                    <span></span>
                  </li>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
      <ContactModal toggle={toggle} />
    </Wrapper>
  );
};
