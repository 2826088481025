import { ButtonStyled, ButtonLinkStyled } from "./button.styles";

export const Button = ({
  children,
  icon = null,
  iconPosition = "end",
  type,
  background,
  fontSize,color,
  fontWeight,
  className,
  hoverBackground,
  hoverColor,
  ...props
}) => {
  return (
    <>
      {type === "link" ? (
        <ButtonLinkStyled
          hoverBackground={hoverBackground}
          hoverColor={hoverColor}
          className={className}
          background={background}
          color={color}
          fontSize={fontSize}
          fontWeight={fontWeight}
          {...props}
        >
          {iconPosition === "start" && <span>{icon}</span>}
          {children}
          {iconPosition === "end" && <span>{icon}</span>}
        </ButtonLinkStyled>
      ) : (
        <ButtonStyled
          hoverBackground={hoverBackground}
          hoverColor={hoverColor}
          className={className}
          background={background}
          color={color}
          fontSize={fontSize}
          fontWeight={fontWeight}
          {...props}
        >
          {iconPosition === "start" && <span>{icon}</span>}
          {children}
          {iconPosition === "end" && <span>{icon}</span>}
        </ButtonStyled>
      )}
    </>
  );
};
