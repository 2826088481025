import { Modal } from "reactstrap";
import { between, up } from "styled-breakpoints";
import styled from "styled-components";


export const CustomModal = styled(Modal)`
  max-width: 637px;
  --bs-modal-width: 566px;
  border-radius: 22px;
  .modal-content {
    padding: 20px !important;
    ${up("md")} {
      padding: 36px !important;
    }
    border-radius: 22px;
    max-height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  .modal-header {
    padding-top: 40px;
    border-bottom: none !important;
    h5 {
      font-weight: 500;
      font-size: 27.6284px;
      line-height: 112.4%;
      ${up("md")} {
        font-size: 48px;
        line-height: 53px;
      }

      span.green {
        color: rgba(11, 156, 7, 1);
      }
    }
    .btn-close {
      display: none;
    }
    .close-btn {
      position: absolute;
      top: 4px;
      ${up("md")} {
        top: 7px;
      }
      right: 4px;
      cursor: pointer;
    }
  }
  .modal-body {
    .form-label {
      font-weight: 500;
      font-size: 16px;
    }
    .mb-3 {
      margin-bottom: 1.5rem !important;
    }
    .input-group {
      .input-group-text {
        border-radius: 50px;
        background-color: unset;
        border: 1px solid #262626;
        border-right: none;
        padding-right: 0;
      }
      .form-control {
        border: 1px solid #262626;
        border-left: 0;
        border-radius: 50px;
        padding: 14px;
        padding-left: 8px;
      }
      .form-control:focus {
        box-shadow: none;
      }
    }
    textarea.form-control {
      border-radius: 25px;
      padding: 20px;
      border: 1px solid #262626;
    }
    textarea.form-control:focus {
      box-shadow: none;
      border-color: unset;
    }
  }
  .modal-footer {
    border-top: none !important;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      background: #0b9c07;
      padding: 8.5px;
      font-weight: 600;
      font-size: 20px;
      width: 100%;
      svg {
        margin-left: 4px;
      }
    }
  }
`;
