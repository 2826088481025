import styled from "styled-components";

export const ButtonStyled = styled.button`
  color: ${(props) => props.color || "#ffff"};
  background: ${(props) => props.background || "#0b9c07"};
  font-weight: ${(props) => props.fontWeight || 500};
  font-size: ${(props) => props.fontSize || "1em"};
  cursor: pointer;
  line-height: 1.25rem;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  transition: background-color 0.1s ease-in;

  /* background: #0b9c07; */
  padding: 6px 4px;
  border-radius: 24px;

  svg {
    width: 1em;
    height: 1em;
    font-size: inherit;
    font-weight: 800;
  }
  &:hover {
    /* background: ${(props) => props.background || "#0b9c07"}; */
    background: ${(props) => props.hoverBackground || props.background};
    color: ${(props) => props.hoverColor || props.color};
  }
`;
export const ButtonLinkStyled = styled.a`
  color: ${(props) => props.color || "#ffff"};
  background: ${(props) => props.background || "#0b9c07"};
  font-weight: ${(props) => props.fontWeight || 500};
  font-size: ${(props) => props.fontSize || "1em"};
  cursor: pointer;
  line-height: 1.25rem;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  transition: background-color 0.1s ease-in;

  /* background: #0b9c07; */
  padding: 6px 4px;
  border-radius: 24px;

  svg {
    width: 1em;
    height: 1em;
    font-size: inherit;
    font-weight: 800;
  }
  &:hover {
    /* background: ${(props) => props.background || "#0b9c07"}; */
    background: ${(props) => props.hoverBackground || props.background};
    color: ${(props) => props.hoverColor || props.color};
  }
`;