import {memo, useLayoutEffect, useRef} from 'react';
import {Wrapper} from './pageloader.styles';
import agrogridLogo from '../../assets/images/agrogrid-logo.svg';
import gsap from 'gsap';

export const PageLoader = memo(() => {
  const rotatingLoaderRef = useRef();
  const root = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(rotatingLoaderRef.current, {
        duration: 5,
        rotation: '+=360',
        scale: '+=2.5',
        repeat: -1,
      });
    }, root);
    return () => ctx.revert();
  }, []);
  return (
    <Wrapper>
      <div ref={rotatingLoaderRef}>
        <img src={agrogridLogo} alt="spinning logo" />
      </div>
    </Wrapper>
  );
});
