import { Wrapper } from "./footer.styles";
import {
  BsArrowUpRight,
  BsFacebook,
  BsInstagram,
  BsTiktok,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { InstagramSquareIcon } from "../../assets/icons/Instagram";
import { Link } from "react-router-dom";
import AgrogridBrandLogo from "../../assets/images/agrogrid-brand.png";
import { AppStoreBadge } from "../../assets/icons/appStore";
import { AppleStoreBadge } from "../../assets/icons/AppIcon/AppleStore/appleStore";
import { GooglePlayBadge } from "../../assets/icons/AppIcon/GooglePlay/googlePlay";
import FacebookIcon from "../../assets/icons/icons8-Facebook.svg";
import InstagramIcon from "../../assets/icons/icons8-instagram 3.svg";
import TwitterIcon from "../../assets/icons/icons8-Twitter.svg";
import { Button } from "../Button";
import {
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { CiMail } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { activateNotification } from "../../utils/notifications-utils";
import { validateEmail } from "../../utils/email-utils";
import { joinWaitlist } from "../../api/firebase.requests";

export const FooterComponent = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
      const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (validateEmail(email) === true) {
          setLoading(true);
          await joinWaitlist(email)
            .then((data) => {
              activateNotification({
                title: "Success",
                body: "You have successfully joined our newsletter.",
              });
              // console.log(data);
            })
            .catch((err) => {
              activateNotification({
                title: "Error",
                body: JSON.stringify(err),
              });
            }).finally(()=>{
          setEmail("");
          setLoading(false);
            });
        } else {
          activateNotification({
            title: "Error",
            body: "This email address is not valid",
          });
          setEmail("");
        }
      };

  return (
    <Wrapper>
      <div className="first_section ">
        <Container>
          <div className="social_links">
            <a href="/" className="agrogrid_brand">
              <img
                src={AgrogridBrandLogo}
                alt="Agrogrid Brand Logo"
                className="agrogrid-brand"
              />
            </a>
            <div className="social_btns">
              <p>Follow Us On:</p>
              <div className="">
                <a
                  href="https://www.facebook.com/profile.php?id=100086411931184"
                  target="_blank"
                >
                  <img src={FacebookIcon} width={30} height={30} alt="" />
                </a>
                <a
                  href="https://twitter.com/agrogrid?t=J2330x4GOeVQIIDp5ejcSQ&s=09"
                  target="_blank"
                >
                  <img src={TwitterIcon} width={30} height={30} alt="" />
                </a>
                <a
                  href="https://instagram.com/agro_grid?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <img src={InstagramIcon} width={30} height={30} alt="" />
                </a>
                <a href="https://www.linkedin.com/company/agrogrid/">
                  <BsLinkedin size={26} />
                </a>
              </div>
            </div>
          </div>
          <div className="navigations_and_contact">
            <div className="context">
              <div className="download">
                <h5 className="title">Download</h5>
                <h6 className="subtitle">Install AgroGrid App On</h6>
                  <AppleStoreBadge height={"100%"} width="77px" />
                  <GooglePlayBadge height={"100%"} width="77px" />
              </div>
              <div className="navigation">
                <h5 className="title">Navigation</h5>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#our-story">About Us</a>
                  </li>
                  <li>
                    <a href="/farms">Our Farms</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="newsletter">
                <h5 className="title">Stay Updated</h5>
                <h6 className="subtitle">Join our newsletter today</h6>
                <form onSubmit={handleEmailSubmit}>
                  <FormGroup className="form_group">
                    <span className="form_icon">
                      <MdOutlineMail color="#808080" fontSize={20} />
                    </span>
                    <input
                      type="email"
                      placeholder="Email address"
                      name="subscribe_email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id=""
                    />
                  </FormGroup>
                  <Button
                    type={"submit"}
                    icon={
                      <BsArrowUpRight
                        fontWeight={"900"}
                        strokeWidth={1.2}
                        size={25}
                      />
                    }
                    background="#FA9730"
                    hoverBackground={"rgb(195 116 32)"}
                  >
                    <span>Subscribe</span>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <hr />
      <div className="contact_us">
        <Container>
          <div>
            <span className="icon">
              <CiMail strokeWidth={"1.5px"} stroke="#262626" size={20} />
            </span>
            <a href="mailto:info@agrogrid.com.ng">info@agrogrid.com.ng</a>
          </div>
          {/* <div>
            <span className="icon">
              <IoCallOutline strokeWidth={"1.5px"} stroke="#262626" size={20} />
            </span>
            <a href="tel:+2347068448242">+(234) 706 844 8242</a>
          </div> */}
          <div>
            <span className="icon">
              <CiLocationOn strokeWidth={"1.5px"} stroke="#262626" size={20} />
            </span>
            <a href="http://">Lagos, Nigeria</a>
          </div>
        </Container>
      </div>
      <div className="privacy_and_conditions">
        <Container className="d-flex">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-and-conditions">Terms of Service</a>
          <p className="copyright-text">
            © {new Date().getFullYear()} AgroGrid. All rights reserved.
          </p>
        </Container>
      </div>
    </Wrapper>
  );
};
