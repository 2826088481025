import {createContext, useContext} from 'react';
import {StoresArchive} from './store-archive';

export const StoreProvider = createContext({});
export const useStoreContext = () => useContext(StoreProvider);

export const StoreContextProvider = ({children}) => {
  return (
    <StoreProvider.Provider value={StoresArchive}>
      {children}
    </StoreProvider.Provider>
  );
};
