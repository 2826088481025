import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { getColorFromTheme } from "../utils/style-utils";
import { reset } from "./reset";

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  ${reset}

  body {
    font-family: ${(props) => props.theme.fontFamily};
    margin: 0;
    padding: 0; 
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
    height: 100%;
      background: #ffffff;
    overflow-x: hidden;
    
    }
  html, body{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  * {
      box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color: #e5e7eb;
  }
  img, video{
    max-width: 100%;
  height: auto;
  vertical-align: middle;
  }
  input:not([type="image" i], [type="range" i], [type="checkbox" i], [type="radio" i]) {
    overflow-clip-margin: 0px !important;
    overflow: clip !important;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0px;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
  a:focus:not(.focus-visible),
  *:focus {
    outline: none;
    box-shadow: none;
  }

  a.focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${getColorFromTheme("yellow100")};
  }
  .rnc__notification-container--top-right{
    position: absolute !important;;
  }
`;
